var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import '../../utils/highlight';
import ReactQuill from 'react-quill';
import 'quill-mention';
import { StyledEditor } from './styles';
import EditorToolbar, { formats } from './EditorToolbar';
import { dispatch } from '../../redux/store';
import { getOfficesAndUsers } from '../../redux/slices/todos/actions';
import { isUser } from '../../sections/@dashboard/todos/utils';
// ----------------------------------------------------------------------
var mentionModule = {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ['@'],
    source: function (searchTerm, renderList) {
        dispatch(getOfficesAndUsers({ page: 1, limit: 5, search: searchTerm }))
            .unwrap()
            .then(function (res) {
            var _a = res.data, offices = _a.offices, users = _a.users;
            var docs = __spreadArray(__spreadArray([], offices, true), users, true);
            var mentionList = docs.map(function (doc) { return ({
                id: doc._id,
                value: isUser(doc) ? (doc === null || doc === void 0 ? void 0 : doc.username) || (doc === null || doc === void 0 ? void 0 : doc.email) : doc === null || doc === void 0 ? void 0 : doc.name,
            }); });
            var regex = new RegExp(searchTerm);
            if (!searchTerm || regex.test('everyone')) {
                mentionList = __spreadArray([{ id: 'everyone', value: 'everyone' }], mentionList, true);
            }
            renderList(mentionList);
        })
            .catch(function (err) { return console.log(err); });
    },
};
export default function Editor(_a) {
    var _b = _a.id, id = _b === void 0 ? 'minimal-quill' : _b, error = _a.error, value = _a.value, onChange = _a.onChange, _c = _a.simple, simple = _c === void 0 ? false : _c, helperText = _a.helperText, sx = _a.sx, showLinkVideoImage = _a.showLinkVideoImage, other = __rest(_a, ["id", "error", "value", "onChange", "simple", "helperText", "sx", "showLinkVideoImage"]);
    var modules = {
        mention: mentionModule,
        toolbar: {
            container: "#".concat(id),
        },
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true,
        },
        syntax: true,
        clipboard: {
            matchVisual: false,
        },
    };
    return (_jsxs(_Fragment, { children: [_jsxs(StyledEditor, __assign({ sx: __assign(__assign({}, (error && {
                    border: function (theme) { return "solid 1px ".concat(theme.palette.error.main); },
                })), sx) }, { children: [_jsx(EditorToolbar, { id: id, isSimple: simple, showLinkVideoImage: showLinkVideoImage }), _jsx(ReactQuill, __assign({ value: value, onChange: onChange, modules: modules, formats: formats, placeholder: "Write something awesome..." }, other))] })), helperText && helperText] }));
}
